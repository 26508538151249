import type { NextPage } from 'next';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import MainLayout from '../app/components/Layouts/MainLayout';

const Home: NextPage = () => {
	const { t } = useTranslation();
	return (
		<MainLayout>
			<div id="main-area">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="text-center not-found-container">
								<div className="title-404">404</div>
								<div className="desc-404">{t('notFound')}</div>

								<div className="mt-4">
									<Link className="btn btn-yellow" href="/">
										<span>{t('returnHome')} </span>
										<span>
											<i className="icon-arrow" />
										</span>
									</Link>
								</div>
								<br />
							</div>
						</div>
					</div>
				</div>
			</div>
		</MainLayout>
	);
};

export default Home;
